var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"elevation-0 pa-0",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs4":""}},[_c('DatePicker',{attrs:{"label":"Plannifier le","objectForm":_vm.formForItems,"fieldName":"tsk_date_plan"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs4":""}},[_c('DatePicker',{attrs:{"label":"Date de cloture","objectForm":_vm.formForItems,"fieldName":"tsk_date_end","title":"Cloture si date"},on:{"update:objectForm":function($event){_vm.formForItems=$event},"update:object-form":function($event){_vm.formForItems=$event}}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs4":""}})],1)],1)],1),_c('v-card',{staticClass:"mt-0 pa-0 elevation-0",attrs:{"fluid":"","color":"#eeeeee"}},[_c('v-card-text',{staticClass:"pa-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"tsk_id","show-select":"","dense":"","items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem,"loading":_vm.loading.getgetTaskItems},scopedSlots:_vm._u([{key:"item.tsk_sar_id",fn:function(ref){
var item = ref.item;
return [((_vm.$route.params.selectedTask && _vm.$route.params.selectedTask.indexOf(item.tsk_id) >= 0))?_c('v-chip',{attrs:{"x-small":"","outlined":"","label":"","color":"blue"}},[_c('v-icon',[_vm._v(" mdi-vector-line ")]),_vm._v(_vm._s(item.sar_code + '-' + item.sar_letter)+" ")],1):_c('span',[_vm._v(_vm._s(item.sar_code + '-' + item.sar_letter))])]}},{key:"item.tsk_tty_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"110px"},attrs:{"x-small":"","outlined":"","label":"","color":item.tty_color,"title":item.tty_name}},[_vm._v(" "+_vm._s(item.tty_name)+" ")])]}},{key:"item.tsk_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"120px"},attrs:{"x-small":"","outlined":"","label":"","color":_vm.getCacheType('TSK', item.tsk_typ_id).typ_color,"title":_vm.getCacheType('TSK', item.tsk_typ_id).typ_name}},[_vm._v(" "+_vm._s(_vm.getCacheType('TSK', item.tsk_typ_id).typ_name)+" ")])]}},{key:"item.tsk_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheState('TSK', item.tsk_sta_id).sta_color}},[_vm._v(" "+_vm._s(_vm.getCacheState('TSK', item.tsk_sta_id).sta_label)+" ")])]}},{key:"item.tsk_id",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"dot":!item.tse_tsk_id_count,"color":(item.tse_tsk_id_count) ? 'green' : 'grey',"content":(item.tse_tsk_id_count) ? item.tse_tsk_id_count : 0}},[_c('v-btn',{attrs:{"small":"","color":"black","icon":""},on:{"click":function($event){_vm.loadEquipment(item.tsk_id); _vm.equipmentMenu[item.tsk_id] = true;}}},[_c('v-icon',[_vm._v("mdi-tow-truck")])],1)],1),_c('v-dialog',{staticStyle:{"z-index":"2500"},attrs:{"hide-overlay":"","width":"600","persistent":""},model:{value:(_vm.equipmentMenu[item.tsk_id]),callback:function ($$v) {_vm.$set(_vm.equipmentMenu, item.tsk_id, $$v)},expression:"equipmentMenu[item.tsk_id]"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{staticClass:"elevation-2",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-truck")]),_c('v-toolbar-title',[_vm._v("Materiel")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":""},nativeOn:{"click":function($event){_vm.saveEquipment(item.tsk_id); item.tse_tsk_id_count = _vm.objTask._pivot.equipments.length; _vm.equipmentMenu[item.tsk_id] = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(!_vm.loading.taskEquipment[item.tsk_id])?_c('AutocompleteMulti',{attrs:{"title":'Materiel',"ressource":'equipments',"listObject":_vm.objTask._pivot.equipments,"listParent":_vm.objTask._data.equipments,"fields":{ 
                                        index: 'equ_id', text:'equ_name', autofocus: false, zindex: 2500, deletablechips: true,
                                        search:'equ_name', preprendIcon:'mdi-hammer-screwdriver', multiSelect:true, prefetch: true, params:'' 
                                }},on:{"update:listObject":function($event){return _vm.$set(_vm.objTask._pivot, "equipments", $event)},"update:list-object":function($event){return _vm.$set(_vm.objTask._pivot, "equipments", $event)},"update:listParent":function($event){return _vm.$set(_vm.objTask._data, "equipments", $event)},"update:list-parent":function($event){return _vm.$set(_vm.objTask._data, "equipments", $event)}}}):_vm._e()],1)],1)],1)]}},{key:"item.tsk_date_plan",fn:function(ref){
                                var item = ref.item;
return [_c('DatePicker',{attrs:{"preicon":"","label":"Date plan","objectForm":item,"fieldName":"tsk_date_plan"}})]}},{key:"item.tsk_date_close",fn:function(ref){
                                var item = ref.item;
return [_c('DatePicker',{attrs:{"preicon":"","label":"Fin / clôture","objectForm":item,"fieldName":"tsk_date_end","title":'Clôture si date (' + item.tsk_date_close + ')'}})]}},{key:"pageText",fn:function(ref){
                                var pageStart = ref.pageStart;
                                var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}],null,true),model:{value:(_vm.taskSelected),callback:function ($$v) {_vm.taskSelected=$$v},expression:"taskSelected"}},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":( !_vm.loading.getDocument ),"color":"error","icon":"mdi-alert"}},[_vm._v(" Aucune tache ")])],1)],2)],1),_c('v-card-actions',{staticClass:"pl-4 pr-4"},[_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){return _vm.closeSlider()}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v("Fermer")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":'green',"disabled":_vm.loading.saveAction},on:{"click":function($event){return _vm.saveTasks('plan')}}},[_c('v-icon',[_vm._v("mdi-content-save-all-outline")]),_vm._v("Enregistrer ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }