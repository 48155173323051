<template>
<v-container fluid class="elevation-0 pa-0">
    <v-card>
        <v-card-text>
            <v-layout row wrap>
                <v-flex xs4 class="pa-2">
                    <DatePicker label="Plannifier le" :objectForm="formForItems" fieldName='tsk_date_plan'></DatePicker>
                </v-flex>
                <v-flex xs4 class="pa-2">
                    <DatePicker label="Date de cloture" :objectForm.sync="formForItems" fieldName='tsk_date_end' title="Cloture si date"></DatePicker>
                </v-flex>
                <v-flex xs4 class="pa-2">
                    <!--<v-text-field label="" type="time" v-model="formForItems.tsk_duration" >
                        <template v-slot:append-outer>
                        <v-btn small :color="'green'" :disabled="loading.saveAction" @click="divideDuration()">
                            <v-icon>mdi-clock-outline</v-icon>Répartir
                        </v-btn>
                        </template>
                    </v-text-field> -->
                </v-flex>
                <!-- <v-flex xs4 class="pa-2">
                    <v-select 
                        prepend-icon="mdi-progress-wrench" :items="getCacheType('TSK')" 
                        item-text="typ_name" item-value="typ_id" v-model="objTask.tsk_typ_id" label="Nature prestation" single-line></v-select>
                </v-flex> -->
            </v-layout>
        </v-card-text>
    </v-card>
        <!-- Associative Tab -->
    <v-card fluid class="mt-0 pa-0 elevation-0" color="#eeeeee">
        <v-card-text class="pa-2">
        <v-data-table v-bind:headers="headers" v-model="taskSelected" :items="items" item-key="tsk_id" show-select dense
            items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getgetTaskItems"> 
            <!-- <template v-slot:[`item.sar_code`]="{ item }">
                <span class="d-inline-block text-truncate" style="max-width: 100px;">
                {{ item.sar_code }}
                </span>
            </template> -->
            <template v-slot:[`item.tsk_sar_id`]="{ item }">
                <v-chip x-small outlined label color="blue" v-if="($route.params.selectedTask && $route.params.selectedTask.indexOf(item.tsk_id) >= 0)">
                    <v-icon>
                        mdi-vector-line
                    </v-icon>{{item.sar_code + '-' + item.sar_letter}}
                </v-chip>
                <span v-else>{{item.sar_code + '-' + item.sar_letter}}</span>

            </template>
            <template v-slot:[`item.tsk_tty_id`]="{ item }">
                <v-chip x-small outlined label :color="item.tty_color" :title="item.tty_name" 
                    class="d-inline-block text-truncate" style="max-width: 110px;">
                    {{item.tty_name}}
                </v-chip>
            </template>
            <template v-slot:[`item.tsk_typ_id`]="{ item }">
                <v-chip x-small outlined label :color="getCacheType('TSK', item.tsk_typ_id).typ_color" 
                    class="d-inline-block text-truncate" style="max-width: 120px;"
                    :title="getCacheType('TSK', item.tsk_typ_id).typ_name">
                    {{ getCacheType('TSK', item.tsk_typ_id).typ_name }}
                </v-chip>
            </template>
            <!-- <template v-slot:[`item.tsk_duration`]="{ item }">
                <v-text-field label="" type="time" v-model="item.tsk_duration_h" ></v-text-field>
            </template>-->
            <template v-slot:[`item.tsk_sta_id`]="{ item }">
                <v-chip small outlined label :color="getCacheState('TSK', item.tsk_sta_id).sta_color">
                {{ getCacheState('TSK', item.tsk_sta_id).sta_label }}
                </v-chip>
            </template>
            <template v-slot:[`item.tsk_id`]="{ item }">
                    <v-badge :dot="!item.tse_tsk_id_count" :color="(item.tse_tsk_id_count) ? 'green' : 'grey'" :content="(item.tse_tsk_id_count) ? item.tse_tsk_id_count : 0" >
                        <v-btn small color="black" icon @click="loadEquipment(item.tsk_id); equipmentMenu[item.tsk_id] = true;"><v-icon>mdi-tow-truck</v-icon></v-btn>
                    </v-badge>
                    <v-dialog v-model="equipmentMenu[item.tsk_id]" hide-overlay width="600" style="z-index:2500;" persistent>
                        <v-card class="mx-auto" >
                            <v-toolbar color="primary" dark class="elevation-2" height="50px">
                                <v-icon class="mr-3">mdi-truck</v-icon>
                                <v-toolbar-title>Materiel</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon color="" 
                                    @click.native="saveEquipment(item.tsk_id); item.tse_tsk_id_count = objTask._pivot.equipments.length; equipmentMenu[item.tsk_id] = false"><v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <!-- @change="saveEquipment(item.tsk_id); item.tse_tsk_id_count = objTask._pivot.equipments.length" -->
                                <AutocompleteMulti v-if="!loading.taskEquipment[item.tsk_id]"
                                    :title="'Materiel'" :ressource="'equipments'"
                                    :listObject.sync="objTask._pivot.equipments" :listParent.sync="objTask._data.equipments" 
                                    :fields="{ 
                                        index: 'equ_id', text:'equ_name', autofocus: false, zindex: 2500, deletablechips: true,
                                        search:'equ_name', preprendIcon:'mdi-hammer-screwdriver', multiSelect:true, prefetch: true, params:'' 
                                }"></AutocompleteMulti>
                            </v-card-text>
                            <!-- <v-card-actions><v-btn text color="teal accent-4" @click="saveEquipment(item.tsk_id)">Enregistrer</v-btn></v-card-actions> -->
                        </v-card>
                    </v-dialog>

            </template>
            <template v-slot:[`item.tsk_date_plan`]="{ item }">
                <DatePicker preicon="" label="Date plan" :objectForm="item" fieldName='tsk_date_plan'></DatePicker>
            </template>
            <template v-slot:[`item.tsk_date_close`]="{ item }">
                <DatePicker preicon="" label="Fin / clôture" :objectForm="item" fieldName='tsk_date_end' :title="'Clôture si date (' + item.tsk_date_close + ')'" ></DatePicker>
            </template>
            <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            De {{ pageStart }} à {{ pageStop }}
            </template>
            <template slot="no-data">
            <v-alert :value="( !loading.getDocument )" color="error" icon="mdi-alert">
                Aucune tache
            </v-alert>
            </template>
        </v-data-table>
        </v-card-text>
        <v-card-actions class="pl-4 pr-4">
            <v-btn  color="grey" @click="closeSlider()"><v-icon>mdi-close</v-icon>Fermer</v-btn>
            <v-spacer></v-spacer>
            <v-btn  :color="'green'" @click="saveTasks('plan')" :disabled="loading.saveAction">
                <v-icon>mdi-content-save-all-outline</v-icon>Enregistrer
            </v-btn>
        </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
//import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { cacheGetters } from "@/store/cache.module"
import { tskObj }       from '@/mixins/tsk_obj.js'

export default {
    name: 'site_edit',
    props: [ 'toast', 'closeSlider' ],
    mixins: [ tskObj ],
    components: { DatePicker, AutocompleteMulti },
    data: function () {
        return {
            loading: { getgetTaskItems: false, taskEquipment: {} },
            validFrom: true,
            pagination: { totalItems: 0, rowsPerPage: 15, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Parcelle',      value: 'tsk_sar_id',   align: 'left' },
                { text: 'Action',        value: 'tsk_tty_id', align: 'left' },
                { text: 'Type',          value: 'tsk_typ_id',   align: 'left' },
                //{ text: 'Temps',         value: 'tsk_duration', align: 'left' },
                { text: 'Etat',          value: 'tsk_sta_id', align: 'left' },
                { text: 'Mat.',          value: 'tsk_id', align: 'left' },
                { text: 'Date plan.',    value: 'tsk_date_plan',    align: 'left', width: '155px' },
                { text: 'Date cloture.', value: 'tsk_date_close',   align: 'left', width: '155px' },
            ],
            formForItems: {
                tsk_date_plan: null,
                tsk_date_end: null,
                tsk_duration: '00:00'
            },
            items: [],
            listTaskTypeItem: [],
            listTaskTypeItemSource: [],
            taskSelected: [],
            autoComplete: {
                user      : [],
            },
            autoCompleteSource:{
                user     : [],
            },
            equipmentMenu: {}
        }
    },
    beforeMount: function(){
        //this.formForItems.tsk_date_plan = this.$date().format('YYYY-MM-DD')
    },
    mounted: function (){
        this.getTaskWithAction()
    },
    methods: {
        async getTaskWithAction(){
            this.loading.getgetTaskItems = true
            let areas = []
            if( this.$route.params.subAreaSelected ){
                this.$route.params.subAreaList.forEach(element => {
                    if( this.$route.params.subAreaSelected.indexOf(element.sar_id) >= 0 ){
                        areas.push(element.sar_id)
                    //    this.items.push(element)
                    //    this.taskSelected.push(element)
                    }
                })
            }

            let url    = '?per_page=1000&extended=withequipment,withtasktype,withsubarea'
            let option = cacheGetters.getFilterUrl()
            if( !option.length ){
                return false // if no filter just clear list
            }
            url    += ( option.length ) ? '&' + option : ''
            this.getTaskIndex(false, false, url + '&tsk_sar_id=' + areas.join()).then( () => {
                for (const item of this.listTask) {
                    this.$set(item, 'tsk_duration_h', this.decimalTimeToStr(item.tsk_duration))
                    this.$set(this.loading.taskEquipment, item.tsk_id, true)
                    this.$set(this.equipmentMenu, item.tsk_id, false)
                }
                this.items        = this.listTask
                for (const item of this.listTask) {
                    if(item.tsk_sta_id !== 'TSK05'){
                        this.taskSelected.push(item)
                    }
                }
                this.loading.getgetTaskItems = false
            })
        },
        async viewTasks(){

        },
        divideDuration(){
            if( !this.taskSelected.length ){
                return false
            }
            let res     = this.formForItems.tsk_duration.split(":");
			let pad     = "00";
			let minute  = ( parseInt(res[0]) * 60 ) + parseInt(res[1]);
			minute      = minute / parseInt( this.taskSelected.length );
			let toHour  = ( Math.floor( parseInt(minute) / 60) ) + '';
			let toMinute= ( parseInt(minute) % 60 ) + '';
			let dispatch = pad.substring(0, pad.length - toHour.length) + toHour + ':' + pad.substring(0, pad.length - toMinute.length) + toMinute;
            for(const key in this.taskSelected){
                this.$set(this.taskSelected[key], 'tsk_duration_h', dispatch) //this.taskSelected[key].tsk_duration_h = dispatch
            }
        },
        async saveTasks(){
            for(const key in this.taskSelected){
                if( this.formForItems.tsk_date_plan ){
                    this.taskSelected[key].tsk_date_plan = this.formForItems.tsk_date_plan
                }
                if( this.formForItems.tsk_date_end ){
                    this.taskSelected[key].tsk_date_end = this.formForItems.tsk_date_end
                    this.taskSelected[key].tsk_sta_id = 'TSK05'
                } else if( this.taskSelected[key].tsk_date_end ){
                    this.taskSelected[key].tsk_sta_id = 'TSK05'
                }

                this.taskSelected[key].tsk_duration = parseInt(this.taskSelected[key].tsk_duration_h.split(':')[0]) + parseFloat(this.taskSelected[key].tsk_duration_h.split(':')[1] / 60)
                let taskType = this.taskSelected[key]
                await this.putTaskStore( taskType )
            }
            this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
            this.closeSlider()
            //close 
        },
        async loadEquipment(tskId){
            this.loading.taskEquipment[tskId] = true
            this.objTask.tsk_id = tskId
            await this.getTaskShow('?include=equipments')
            this.loading.taskEquipment[tskId] = false
        },
        async saveEquipment(){
            await this.putTaskStore()
            //this.loading.taskEquipment[tskId] = true
            //this.equipmentMenu[tskId] = false
        },
        decimalTimeToStr(num) {
            if (Number.isInteger(num)) {
                return 0;
            }
            const minuteStr = Math.round( parseFloat('0.' + num.toString().split('.')[1]) * 60 ) + "";
            const hourStr   = num.toString().split('.')[0];

            return hourStr.padStart(2, '0') + ':' + minuteStr.padStart(2, '0');
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState   : cacheGetters.getStates,
    },
}
</script>
