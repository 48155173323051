<template>
<v-container fluid class="elevation-0 pa-0">
    <v-card>
        <v-card-text>
            <v-layout row wrap>
                <v-flex xs4 class="pa-2">
                    <DatePicker label="Plannifier le" :objectForm="objTask" fieldName='tsk_date_plan'></DatePicker>
                </v-flex>
                <v-flex xs4 class="pa-2">
                    <DatePicker label="Date de fin" :objectForm.sync="objTask" fieldName='tsk_date_end'></DatePicker>
                </v-flex>
                <v-flex xs4 class="pa-2">
                    <v-select 
                        prepend-icon="mdi-progress-wrench" :items="getCacheType('TSK')" 
                        item-text="typ_name" item-value="typ_id" v-model="objTask.tsk_typ_id" label="Nature prestation" single-line></v-select>
                </v-flex>
                <v-flex xs12 class="pa-2">
                    <AutocompleteMulti 
                        :title="'Actions'" :ressource="'tasktypes'"
                        :listObject.sync="listTaskTypeItem" :listParent.sync="listTaskTypeItemSource" 
                        :fields="{ 
                            index: 'tty_id', text:'tty_name', autofocus: true,
                            search:'tty_name', preprendIcon:'mdi-table-merge-cells', multiSelect:true, prefetch: true, params:'' 
                    }"></AutocompleteMulti>
                </v-flex>
                <v-flex xs12 class="pa-2">
                    <v-text-field label="Commentaire" v-model="objTask.tsk_customfields.tsk_description"></v-text-field>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
        <!-- Associative Tab -->
    <v-card fluid class="mt-0 pa-0 elevation-0" color="#eeeeee">
        <v-card-text>
        <v-data-table v-bind:headers="headers" v-model="subAreaSelected" :items="items" item-key="sar_id" show-select
            items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getgetTaskItems"> 
            <template v-slot:[`item.sar_code`]="{ item }">
                <span class="d-inline-block text-truncate" style="max-width: 400px;">
                {{ item.sar_code }}
                </span>
            </template>
            <template v-slot:[`item.sar_letter`]="{ item }">
                {{item.sar_letter}}
            </template>
            <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            De {{ pageStart }} à {{ pageStop }}
            </template>
            <template slot="no-data">
            <v-alert :value="( !loading.getDocument )" color="error" icon="mdi-alert">
                Aucune fichier, ajoutez un nouveau fichier
            </v-alert>
            </template>
        </v-data-table>
        </v-card-text>
        <v-card-actions class="pl-4 pr-4">
            <v-btn  color="grey" @click="closeSlider()"><v-icon>mdi-close</v-icon>Fermer</v-btn>
            <v-spacer></v-spacer>
            <v-btn  :color="'green'" @click="saveTasks('plan')" :disabled="loading.saveAction">
                <v-icon>mdi-content-save-all-outline</v-icon>Plannifier
            </v-btn>
            <v-btn  :color="'orange'" @click="saveTasks('resolve')" :disabled="loading.saveAction">
                <v-icon>mdi-content-save-check-outline</v-icon>Plannifier et résourde
            </v-btn>
        </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { cacheGetters } from "@/store/cache.module"
import { tskObj }       from '@/mixins/tsk_obj.js'
import { taskUpdateService } from '@/rxjsServices';

export default {
    name: 'site_edit',
    props: [ 'closeSlider' ],
    mixins: [ tskObj ],
    components: { DatePicker, AutocompleteMulti },
    data: function () {
        return {
            loading: { getgetTaskItems: false },
            validFrom: true,
            pagination: { totalItems: 0, rowsPerPage: 15, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Parcelle', value: 'sar_code',   align: 'left' },
                { text: 'Lettre',   value: 'sar_letter', align: 'left' },
                { text: 'Surface',  value: 'sar_area',   align: 'left' },
            ],
            items: [],
            listTaskTypeItem: [],
            listTaskTypeItemSource: [],
            subAreaSelected: [],
            autoComplete: {
                user      : [],
            },
            autoCompleteSource:{
                user     : [],
            },
            dateMenu: { date_start:false, date_end:false },

        }
    },
    beforeMount: function(){
        this.objTask.tsk_date_plan = this.$date().format('YYYY-MM-DD')
    },
    mounted: function (){
        if( this.$route.params.subAreaSelected ){
            this.$route.params.subAreaList.forEach(element => {
                if( this.$route.params.subAreaSelected.indexOf(element.sar_id) >= 0 ){
                    this.items.push(element)
                    this.subAreaSelected.push(element)
                }
            })
        }
        if( this.$route.params.fence ){
            this.headers[2].text = 'Longueur'
        }
    },
    methods: {
        async getTaskWithAction(){
            await this.getTaskItems()
        },
        async viewTasks(){

        },
        async saveTasks(state){
            if( state == 'resolve' ){
                this.objTask.tsk_sta_id = 'TSK05'
            }
            for(const key in this.subAreaSelected){
                this.objTask.tsk_sar_id = this.subAreaSelected[key].sar_id
                for(const taskType in this.listTaskTypeItem){
                    this.objTask.tsk_tty_id = this.listTaskTypeItem[taskType]
                    await this.postTaskStore()
                }
            }
            if( this.$route.params.fence && this.$route.params.taskLine ){
                this.$http.post( '/taskslinear', { 
                    tal_tsk_id: this.objTask.tsk_id,
                    tal_lat_start: this.$route.params.taskLine[0]._latlng.lat,
                    tal_lng_start: this.$route.params.taskLine[0]._latlng.lng,
                    tal_lat_end  : this.$route.params.taskLine[1]._latlng.lat,
                    tal_lng_end  : this.$route.params.taskLine[1]._latlng.lng,
                    tal_mileage  : this.$route.params.taskLine[2],
                }).then( () => {
                    this.$root.$toast({ color: 'success', text: 'Ligne enregistrée !' })        
                })
            }
            this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
            taskUpdateService.sendTask('create')
            this.closeSlider()
            //close 
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState   : cacheGetters.getStates,
    },
}
</script>
