<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" color="grey lighten-4" height="48px">
        <v-toolbar-title class="body-2 grey--text">
            <v-btn icon><v-icon >mdi-hammer-screwdriver</v-icon></v-btn>{{ '#' + objTask.tsk_id }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip small :color="getCacheType('TSK', objTask.tsk_typ_id).typ_color" :title="getCacheType('TSK', objTask.tsk_typ_id).typ_name" text-color="white">
            <v-btn icon><v-icon >mdi-alert</v-icon></v-btn> {{ getCacheType('TSK', objTask.tsk_typ_id).typ_name }}
        </v-chip>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid class="mt-2 pa-2">

    <!-- Associative Tab --------------------------------------------------------------------------------------------------------->
    <v-card class="pb-3 elevation-3 rounded-lg" style="background-color:#FAFAFA;" light v-if="objTask.tsk_id">
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            
            <v-tab v-for="item in tabItems" :key="item.title" class="pl-1 pr-1 rounded-lg" style="border-bottom-left-radius: 20px !important; background-color:white;"><span class="hidden-sm-and-down"><v-icon class="mr-1" :color="(activeTab.event == 0) ? 'red'  : 'grey'">{{item.icon}}</v-icon>{{ item.title }}</span></v-tab>
        </v-tabs>
        <v-card v-if="activeTab === 0 && showTab == true" class="elevation-0" fluid style="background-color:#FAFAFA;" light>
            <v-card-text>
                <v-layout row wrap class="ma-0 mt-2">
                <v-flex xs6 class="pl-2 pr-2">
                    <DatePicker label="Plannifier le" :objectForm="objTask" fieldName='tsk_date_plan'></DatePicker>
                </v-flex>
                <v-flex xs6 class="pl-2 pr-2">
                    <DatePicker label="Date de fin" :objectForm="objTask" fieldName='tsk_date_end'></DatePicker>
                </v-flex>
                <v-flex xs12 class="pl-2 pr-2">
                    <AutocompleteMulti 
                        :title="'Actions'" :ressource="'tasktypes'"
                        :listObject.sync="autoComplete.listTaskTypeItem" :listParent.sync="autoCompleteSource.listTaskTypeItemSource" 
                        :fields="{ 
                            index: 'tty_id', text:'tty_name', autofocus: true,
                            search:'tty_name', preprendIcon:'mdi-table-merge-cells', multiSelect:false, prefetch: true, params:'' 
                    }"></AutocompleteMulti>
                </v-flex>
                <v-flex xs6 class="pl-2 pr-2">
                    <v-select :disabled="!($root.$permissions.Task.before)"
                        :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" item-text="sta_label" item-value="sta_id"
                        :items="getCacheState('TSK')" v-model="objTask.tsk_sta_id" label="Statut" single-line>
                        <template v-slot:prepend><v-icon :color="getCacheState('TSK', objTask.tsk_sta_id).sta_color">mdi-file-tree</v-icon></template>
                    </v-select>
                </v-flex>
                <v-flex xs6 class="pl-2 pr-2">
                <v-select 
                    :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" item-text="typ_name" item-value="typ_id"
                    :items="getCacheType('TSK')" v-model="objTask.tsk_typ_id" label="Type" single-line>
                    <template v-slot:prepend><v-icon :color="getCacheType('TSK', objTask.tsk_typ_id).typ_color">mdi-progress-check</v-icon></template>
                </v-select>
                </v-flex>
                <v-flex xs12 class="pl-2 pr-2">
                    <v-textarea :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" prepend-icon="mdi-comment"
                        rows="2" label="Commentaire"  v-model="objTask.tsk_customfields.tsk_comment" >
                    </v-textarea>
                </v-flex>
            </v-layout>
            </v-card-text>
        </v-card>

        <DocumentTab   v-if="activeTab === 1 && showTab == true" :routeState="routeState" :doc_xxx_id="objTask.tsk_id" :table_id="'tsk'" @linkDoc="linkedDocument"></DocumentTab>
        <EventTab v-if="activeTab === 2 && showTab == true" :routeState="routeState" :eve_xxx_id="objTask.tsk_id" :table_id="'tsk'" typfilter="" :genfilter="false" title="Evenements"></EventTab>
    </v-card>
    <!-- Save button form -->
    <v-toolbar v-if="activeTab === 0 && showTab == true" extended flat color="transparent" >
        <v-btn rounded color="red" class="mr-3" dark :loading="saveProgress" @click.native="objTask.tsk_sta_id = 'TSK07'; deleteItem();" :disabled="saveProgress">
            <v-icon left>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn rounded color="green" class="mr-3" dark :loading="saveProgress" @click.native="objTask.tsk_sta_id = 'TSK05'; saveForm();" :disabled="saveProgress">
            <v-icon left>mdi-record</v-icon>Terminer
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text color="#595959" dark :href="link.base">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn rounded color="primary" class="mr-3 ml-3" :loading="saveProgress" @click.native="saveForm()" :disabled="(saveProgress) ? true : false">
            <v-icon left>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
    </v-container>
</v-container>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import EventTab    from '@/components/events/EventList.vue'
import DocumentTab from '@/components/documents/DocumentList.vue'

import { cacheGetters } from "@/store/cache.module"
import { tskObj }       from '@/mixins/tsk_obj.js'

import { taskUpdateService } from '@/rxjsServices';

export default {
    name: 'object_edit',
    props: [ 'switchModal', 'routeState', 'link', 'closeSlider' ],
    mixins: [ tskObj ],
    components: {
        DatePicker, AutocompleteMulti,
        EventTab,
        DocumentTab,
    },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            fieldStyle: { outlined: true, dense: true },
            formData    : false,
            obtObj: { _method:'POST', _path:'objecttype/' },
            obtObjClone:'',
            formItem: [],
            activeTab: -1, 
            showTab: false,
            tabItems: [ { title: 'Fiche', icon: 'mdi-traffic-cone'}, { title: 'Fichiers', icon: 'mdi-folder-multiple-outline'}, { title: 'Evenements', icon: 'mdi-alert'} ],
            primaryItem: 30,
            secondaryItem: 4,
            treePic: "",
            listsData: {},
            layerName: '',
            autoComplete: { listTaskTypeItem: {}, },
            autoCompleteSource: { listTaskTypeItemSource: [], }
      }
    },
    mounted: function (){
        // Chargement des info
        setTimeout( () => {
            if( this.$route.params.tsk_id !== undefined && this.$route.params.tsk_id > 0 ){
                this.objTask.tsk_id = this.$route.params.tsk_id
                if( this.objTask.tsk_id > 0 ){
                    this.getForm()
                }
                this.activeTab = 0
            }
            if( this.$route.params.sar_id !== undefined && this.$route.params.sar_id > 0 ){
                this.objTask.tsk_sar_id = this.$route.params.sar_id
            }
            //this.getImage()
        }, 400) 
    },
    methods: {
        async getForm(){
            await this.getTaskShow('?include=tasktype')
            if( this.objTask && this.objTask.tasktype ){
                this.autoComplete.listTaskTypeItem = this.objTask.tasktype.tty_id
                this.autoCompleteSource.listTaskTypeItemSource.push(this.objTask.tasktype)
            }

            this.formData     = true
            this.showTab      = true
        },
        async saveForm(){
            this.saveProgress = true
            this.objTask.tsk_tty_id = this.autoComplete.listTaskTypeItem
            if( !this.objTask.tsk_id ){
                await this.postTaskStore()
                this.$root.$toast({ text: 'Fiche enregistrée !' })
            } else {
                await this.putTaskStore()
                this.$root.$toast({ text: 'Fiche enregistrée !' })
            }
            this.saveProgress = false
            taskUpdateService.sendTask(this.objTask);
            this.closeSlider() //this.$router.push({ name: 'tasks' })
        },
        async deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.deleteTaskDestroy()
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Tache supprimée !' })
                    taskUpdateService.sendTask(this.objTask);
                    this.$router.push({ name: 'tasks' })
                }
            })
        },
        cleanForm(){
            var obtClone = JSON.parse( this.obtObjClone )
            var prop
            for( prop in obtClone ){
                if( this.obtObj[prop] !== undefined ){
                    this.obtObj[prop] = obtClone[prop]
                }
            }
        },
        linkedDocument(doc_id){
            this.getImage(doc_id)
        },
        getImage(doc_id){
            this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Le fichier image n\'existe pas' })
                } else {
                    var reader = new FileReader();
                    reader.readAsDataURL(response.data); 
                    reader.onloadend = () => {
                        var base64data = reader.result;                
                        this.treePic = base64data;
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        getCacheType       : cacheGetters.getTypes,
        getCacheState      : cacheGetters.getStates,
        getCacheSession    : cacheGetters.getSession,
    }
}
</script>