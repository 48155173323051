var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"elevation-0 pa-0",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs4":""}},[_c('DatePicker',{attrs:{"label":"Plannifier le","objectForm":_vm.objTask,"fieldName":"tsk_date_plan"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs4":""}},[_c('DatePicker',{attrs:{"label":"Date de fin","objectForm":_vm.objTask,"fieldName":"tsk_date_end"},on:{"update:objectForm":function($event){_vm.objTask=$event},"update:object-form":function($event){_vm.objTask=$event}}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs4":""}},[_c('v-select',{attrs:{"prepend-icon":"mdi-progress-wrench","items":_vm.getCacheType('TSK'),"item-text":"typ_name","item-value":"typ_id","label":"Nature prestation","single-line":""},model:{value:(_vm.objTask.tsk_typ_id),callback:function ($$v) {_vm.$set(_vm.objTask, "tsk_typ_id", $$v)},expression:"objTask.tsk_typ_id"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('AutocompleteMulti',{attrs:{"title":'Actions',"ressource":'tasktypes',"listObject":_vm.listTaskTypeItem,"listParent":_vm.listTaskTypeItemSource,"fields":{ 
                            index: 'tty_id', text:'tty_name', autofocus: true,
                            search:'tty_name', preprendIcon:'mdi-table-merge-cells', multiSelect:true, prefetch: true, params:'' 
                    }},on:{"update:listObject":function($event){_vm.listTaskTypeItem=$event},"update:list-object":function($event){_vm.listTaskTypeItem=$event},"update:listParent":function($event){_vm.listTaskTypeItemSource=$event},"update:list-parent":function($event){_vm.listTaskTypeItemSource=$event}}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Commentaire"},model:{value:(_vm.objTask.tsk_customfields.tsk_description),callback:function ($$v) {_vm.$set(_vm.objTask.tsk_customfields, "tsk_description", $$v)},expression:"objTask.tsk_customfields.tsk_description"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mt-0 pa-0 elevation-0",attrs:{"fluid":"","color":"#eeeeee"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"sar_id","show-select":"","items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem,"loading":_vm.loading.getgetTaskItems},scopedSlots:_vm._u([{key:"item.sar_code",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"400px"}},[_vm._v(" "+_vm._s(item.sar_code)+" ")])]}},{key:"item.sar_letter",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.sar_letter)+" ")]}},{key:"pageText",fn:function(ref){
                    var pageStart = ref.pageStart;
                    var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}],null,true),model:{value:(_vm.subAreaSelected),callback:function ($$v) {_vm.subAreaSelected=$$v},expression:"subAreaSelected"}},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":( !_vm.loading.getDocument ),"color":"error","icon":"mdi-alert"}},[_vm._v(" Aucune fichier, ajoutez un nouveau fichier ")])],1)],2)],1),_c('v-card-actions',{staticClass:"pl-4 pr-4"},[_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){return _vm.closeSlider()}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v("Fermer")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":'green',"disabled":_vm.loading.saveAction},on:{"click":function($event){return _vm.saveTasks('plan')}}},[_c('v-icon',[_vm._v("mdi-content-save-all-outline")]),_vm._v("Plannifier ")],1),_c('v-btn',{attrs:{"color":'orange',"disabled":_vm.loading.saveAction},on:{"click":function($event){return _vm.saveTasks('resolve')}}},[_c('v-icon',[_vm._v("mdi-content-save-check-outline")]),_vm._v("Plannifier et résourde ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }