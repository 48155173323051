<template>
<v-container fluid class="elevation-0 mt-2 pa-2">
    <v-card class="pb-3 elevation-3 rounded-lg" light v-if="objSubarea.sar_id">
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            
            <v-tab v-for="item in tabItems" :key="item.title" class="pl-1 pr-1 rounded-lg" style="border-bottom-left-radius: 20px !important; background-color:white;"><span class="hidden-sm-and-down"><v-icon class="mr-1" :color="(activeTab.event == 0) ? 'red'  : 'grey'">{{item.icon}}</v-icon>{{ item.title }}</span></v-tab>
        </v-tabs>
        <v-card v-if="activeTab === 0 && showTab == true" class="elevation-0" fluid style="background-color:#FAFAFA;" light>
            <v-card-text style="background-color:white;">
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Code" v-model="objSubarea.sar_code"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Lettre" v-model="objSubarea.sar_letter"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    item-text="typ_name" item-value="typ_id"
                    :items="getCacheType('SAR')" v-model="objSubarea.sar_typ_id" label="Type">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    item-text="typ_name" item-value="typ_id"
                    :items="getCacheType('UNI')" v-model="objSubarea.sar_typ_id_unit" label="Unité">
                </v-select>
                <!-- <v-btn @click="switchFieldAssoc" color="grey" outlined block dark class="mt-3">Association de champs</v-btn> -->
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Surface" v-model="objSubarea.sar_area"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Surface calculée" v-model="objSubarea.sar_area_auto" disabled></v-text-field>
            </v-flex>
            </v-layout>
            </v-card-text>
        </v-card>
    </v-card>
    <EventTab v-if="activeTab === 1 && showTab == true" :routeState="routeState" :eve_xxx_id="objSubarea.sar_id" :table_id="'sar'" title="Alerte"></EventTab>
   <!-- Save button form -->
    <v-toolbar v-if="!activeTab" extended flat color="transparent" >
        <v-btn  color="grey" @click="closeSlider()"><v-icon>mdi-close</v-icon>Fermer</v-btn>
        <v-spacer></v-spacer>
        <v-btn  :color="'green'" @click="saveSubarea()" :disabled="loading.saveAction">
            <v-icon>mdi-content-save-all-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
//import DatePicker from '@/components/ui/DatePicker'
//import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { cacheGetters } from "@/store/cache.module"
import { sarObj }       from '@/mixins/sar_obj.js'
import EventTab    from '@/components/events/EventList.vue'

export default {
    name: 'subarea_edit',
    props: [ 'toast', 'routeState', 'closeSlider' ],
    mixins: [ sarObj ],
    components: { EventTab },
    data: function () {
        return {
            loading: { getgetTaskItems: false, taskEquipment: {} },
            validFrom: true,
            activeTab: -1, 
            showTab: false,
            tabItems: [ { title: 'Fiche', icon: 'mdi-traffic-cone'}, { title: 'Alertes', icon: 'mdi-alert'} ],
        }
    },
    beforeMount: function(){
        //this.formForItems.tsk_date_plan = this.$date().format('YYYY-MM-DD')
    },
    mounted: function (){
        this.getSubArea()
    },
    methods: {
        async getSubArea(){
            this.loading.getgetTaskItems = true
            if(this.$route.params.tsk_id){
                this.objSubarea.sar_id = this.$route.params.tsk_id
                await this.getSubareaShow()
                this.activeTab = 0
                this.showTab   = true
            } else {
                return false
            }
        },
        async saveSubarea(){
            this.putSubareaUpdate()
            this.closeSlider()
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState   : cacheGetters.getStates,
    },
}
</script>
